import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import Html, { extractHeader } from '../Html';
import { tagEvent } from '../../hooks/analytics';
import useResultsTimestamp from '../../hooks/useResultsTimestamp';

function Accordion2(props) {
  const { nodes, ...rest } = props;
  const { handleSelectResults } = useResultsTimestamp();
  if (!nodes) {
    return null;
  }

  function getLabel(id) {
    switch (id) {
      case 'frontpage-results':
        return 'Accordion Results';
      case 'frontpage-agenda':
        return 'Accordion Agenda';
      default:
        return 'Accordion Close';
    }
  }

  function handleSelect(id) {
    handleSelectResults(id);
    tagEvent({ category: 'engagement', action: 'view_item_list', label: getLabel(id) });
  }

  return (
    <Accordion {...rest} onSelect={handleSelect}>
      {nodes?.filter(Boolean).map(extractHeader)
        .map(({ id, header, body }) => (
          <Accordion.Item key={id} eventKey={id} id={id} className="rounded-0 border-0 border-top border-dark">
            <Accordion.Header as="div"><Html html={header?.outerHTML} /></Accordion.Header>
            <Accordion.Body className="px-0"><Html html={body?.innerHTML} /></Accordion.Body>
          </Accordion.Item>
        ))}
    </Accordion>
  );
}

Accordion2.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object),
};

export default Accordion2;
