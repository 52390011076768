import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function useSwipe() {
  const ref = useRef(null);
  const navigate = useNavigate();
  let xDown;
  let yDown;

  function handleTouchStart(event) {
    const firstTouch = event.touches[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchEnd(event) {
    if (!xDown || !yDown) return;
    const xUp = event.changedTouches[0].clientX;
    const yUp = event.changedTouches[0].clientY;
    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;
    const minChange = ref.current.clientWidth / 4;
    if (Math.abs(xDiff) > Math.max(minChange, Math.abs(yDiff))) {
      navigate(Math.sign(xDiff));
    }
  }

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }
    element.addEventListener('touchstart', handleTouchStart, false);
    element.addEventListener('touchend', handleTouchEnd, false);
  }, [ref]);

  return ref;
}

export default useSwipe;
