/* eslint-disable no-param-reassign */
import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import formatDistance from 'date-fns/formatDistance';
import nl from 'date-fns/locale/nl';

/**
 * Set dates from data-timestamp
 * @param {*} html
 * @returns
 */
function setDates(html) {
  const isNews = html.querySelector('#frontpage-news') !== null;
  html.querySelectorAll('.date[data-timestamp]')
    .forEach((elem) => {
      const date = new Date(elem.dataset.timestamp * 1000);
      elem.innerText = isNews
        ? formatDistance(date, new Date(), { locale: nl, addSuffix: true })
        : formatRelative(date, new Date(), { locale: nl });
      elem.title = format(date, 'PPp', { locale: nl });
    });
  return html;
}
export default setDates;
