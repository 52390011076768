import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import getSolarPanels from './getSolarPanels';
import cx from './cx';

function PanelTable(props) {
  const { className } = props;
  const [state, setState] = React.useState({ loading: true });
  useEffect(() => {
    getSolarPanels()
      .then((data) => setState({ data, loading: false }))
      .catch((error) => setState({ error, loading: false }));
  }, []);
  const { data, error, loading } = state;
  if (loading) {
    return <div className="text-center my-4"><Spinner animation="border" variant="secondary" /></div>;
  }
  if (error) {
    return <Alert variant="danger" className="my-2 mx-3">{error.message}</Alert>;
  }

  function getBgStyle(logo) {
    return {
      backgroundImage: `url(${logo})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    };
  }
  const containerClass = 'solar-panels-container d-flex flex-wrap small lh-sm';
  const cellClass = 'sp-cell border p-1 mb-3 text-center d-flex flex-column justify-content-between';
  return (
    <div className={cx(containerClass, className)}>
      {data.map(({ id, name, logo, adopted }) => (
        <div key={id} className={cx(cellClass, !adopted && 'bg-sparta')}>
          <div className="sp-id">{id}</div>
          {logo && <div className="sp-logo flex-grow-1" style={getBgStyle(logo)} />}
          <div className="sp-name"><span>{name}</span></div>
        </div>
      ))}
    </div>
  );
}

PanelTable.propTypes = {
  className: PropTypes.string,
};

export default PanelTable;
