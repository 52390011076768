function rebaseElement(elem, attr) {
  // eslint-disable-next-line no-param-reassign
  elem[attr] = `https://web.avsparta.nl${elem.attributes[attr].value}`;
}

/**
 * Rebase action for forms to web.avsparta.nl
 * @param {*} html
 * @returns
 */
export function rebaseAction(html) {
  Array.from(html.querySelectorAll('form[action]'))
    .filter((form) => form.attributes.action.value.startsWith('/'))
    .forEach((form) => rebaseElement(form, 'action'));
  return html;
}

/**
 * Rebase href for downloads to web.avsparta.nl
 * @param {*} html
 * @returns
 */
export function rebaseHref(html) {
  Array.from(html.querySelectorAll('[href]'))
    .filter((a) => a.attributes.href.value.startsWith('/content/download'))
    .forEach((a) => rebaseElement(a, 'href'));
  return html;
}

/**
 * Rebase src for images to web.avsparta.nl
 * @param {*} html
 * @returns
 */
export function rebaseSrc(html) {
  Array.from(html.querySelectorAll('[src]'))
    .filter((img) => img.attributes.src.value.startsWith('/'))
    .forEach((img) => rebaseElement(img, 'src'));
  return html;
}

function rebase(html) {
  rebaseAction(html);
  rebaseHref(html);
  rebaseSrc(html);
  return html;
}

export default rebase;
