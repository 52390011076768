import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { initSentry } from './sentry';
import './styles/custom.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import router from './router';
import pkg from '../package.json';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import reportWebVitals from './reportWebVitals';
import { sendToGoogleAnalytics } from './hooks/analytics';

// eslint-disable-next-line no-console
console.log('AV Sparta Web App', pkg.version, process.env.NODE_ENV);

initSentry();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
