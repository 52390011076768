import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Header from './Header';
import Footer from './Footer';
import LeftMenu from './LeftMenu';
import useSwipe from './useSwipe';
import SearchButton from './SearchButton';
import Html from '../Html';
import usePage from './usePage';
import useShowMenu from './useShowMenu';
import ShareButton from './ShareButton';

function Page() {
  const { error, loading, topPath } = usePage();
  const [showMenu, setShowMenu] = useShowMenu();
  const swipeRef = useSwipe();

  const main = useMemo(() => {
    if (error) {
      return (
        <>
          <Alert variant="danger" className="my-2 mx-3">{error.message}</Alert>
          {error.html && <Html html={error.html.innerHTML} className="border border-danger rounded p-2 mx-3" />}
        </>
      );
    }
    if (loading) {
      return <div className="text-center my-4"><Spinner animation="border" variant="secondary" /></div>;
    }
    return <Outlet />;
  }, [error, loading]);

  return (
    <div ref={swipeRef} className="page-container container-fluid px-0 bg-white d-flex flex-column my-lg-3">
      <Header className="mb-2 py-2 px-auto" />
      <nav className="bg-sparta mb-2 py-1 px-auto border-bottom border-black small d-flex align-items-baseline align-items-between">
        <button className="btn btn-link d-lg-none py-0 ps-0" type="button" onClick={() => setShowMenu(true)} aria-label="Menu">
          <i className="bi-list fs-2 lh-1 " />
          <span className="d-none d-sm-inline">menu</span>
        </button>
        <Html html={topPath?.innerHTML} loading="&nbsp;" className="flex-grow-1" />
        <SearchButton className="d-md-none" />
        <ShareButton />
      </nav>
      <div className="d-lg-flex h-100 flex-grow-1">
        <LeftMenu show={showMenu} onHide={() => setShowMenu(false)} className="ms-lg-2 me-lg-4 max-w-menu" />
        <main className="flex-grow-1">
          {main}
        </main>
      </div>
      <Footer className="border-top mt-4 py-2" />
    </div>
  );
}

export default Page;
