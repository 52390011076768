import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';
import Home from './components/Home';
import getContent from './components/ContentProvider/getContent';
import Node from './components/Node';
import Page from './components/Page';
import ContentProvider from './components/ContentProvider';
import SolarPanels from './components/SolarPanels';

async function loader({ params }) {
  try {
    const path = `/${params['*'] || ''}`;
    switch (path) {
      case '/invoer/start':
        return redirect('https://web.avsparta.nl/invoer/start');
      default:
        return await getContent(path);
    }
  } catch (err) {
    return err;
  }
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <ContentProvider><Page /></ContentProvider>,
    loader,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'Nieuws/2023/Zonnepanelen',
        element: <SolarPanels />,
      },
      {
        path: '*',
        element: <Node />,
      },
    ],
  },
]);

export default router;
