import React from 'react';
import { Button } from 'react-bootstrap';

function ShareButton() {
  const { href } = window.location;
  async function share() {
    try {
      if (navigator.share) {
        // const lastIndex
        // const docTitle = document.title.replace(/ - Atletiek.+$/, '');
        // const match = docTitle.match(/^(.+?) \/ (.+)$/);
        await navigator.share({
          // title: match ? match[1] : docTitle,
          // text: docTitle,
          url: href,
        });
        return;
      }
      if (navigator.clipboard) {
        navigator.clipboard.writeText(href);
        alert('De link is gekopieerd naar het klembord.'); // eslint-disable-line no-alert
      }
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return (
    <Button variant="link" type="button" size="sm" onClick={share}>
      <i className="bi-share fs-5 lh-1" aria-label="Zoeken" />
    </Button>
  );
}

export default ShareButton;
