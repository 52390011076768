import React from 'react';
import useContent from '../ContentProvider/useContent';
import Html from '../Html';
import PanelTable from './PanelTable';
import './solarPanels.css';

function SolarPanels() {
  const { main } = useContent();
  const link = main?.querySelector('p.text-center a');
  if (link) {
    link.classList.add('btn', 'btn-primary', 'btn-lg');
  }
  return (
    <div className="px-auto">
      <Html html={main?.innerHTML} />
      <PanelTable />
    </div>

  );
}

export default SolarPanels;
