const scriptsLoaded = new Set();

function addScript(src, async = true) {
  if (scriptsLoaded.has(src)) return;
  scriptsLoaded.add(src);

  const script = document.createElement('script');
  script.src = src;
  script.async = async;
  document.body.appendChild(script);
}

export default addScript;
