// import cache from './cache';
import rebase from './rebase';
import { tagEvent } from '../../hooks/analytics';
import setDates from './setDates';

function parseHTML(text) {
  const parser = new DOMParser();
  const html = parser.parseFromString(text, 'text/html');
  rebase(html);
  setDates(html);
  return html;
}

async function getContent(pathname) {
  // console.log('getContent', pathname);
  const t0 = performance.now();
  const res = await fetch(`https://api.avsparta.nl${pathname}`);
  tagEvent({ category: 'performance', action: 'timing_complete', name: pathname, value: Math.round(performance.now() - t0) });
  const text = await res.text();
  const html = parseHTML(text);
  if (res.status >= 400) {
    const error = new Error(`Error ${res.status} ${res.statusText}`);
    error.html = html?.querySelector('.warning');
    throw error;
  }
  return html;
}

export default getContent;
