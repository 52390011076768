import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const YEAR = new Date().getFullYear();
const ENDASH = '\u2013';

function Footer(props) {
  const { className } = props;
  return (
    <footer className={className}>
      <div className="px-auto mb-2">
        <NavLink to="/Contact" className="text-nowrap me-4">Contact opnemen</NavLink>
        <NavLink to="/Over-deze-website/Privacybeleid" className="me-4">Privacyverklaring</NavLink>
        <a href="https://web.avsparta.nl/user/login">Aanmelden</a>
      </div>
      <div className="small text-muted px-auto">
        {`© 2011${ENDASH}${YEAR} AV Sparta (tenzij anders aangegeven). Alle rechten voorbehouden.`}
      </div>
    </footer>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
