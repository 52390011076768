import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import pkg from '../package.json';

export function initSentry() {
  if (window.location.hostname !== 'localhost') {
    Sentry.init({
      dsn: 'https://2b50287dd19a4427b9cee57a35cc303c@o193370.ingest.sentry.io/4504430089404416',
      integrations: [new BrowserTracing()],
      release: pkg.version,
      environment: process.env.NODE_ENV,
      serverName: window.location.hostname,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
    });
  }
}

export function report(error) {
  if (window.location.hostname !== 'localhost') {
    Sentry.captureException(error);
  }
}
