import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

function SearchForm(props) {
  const { autoFocus, ...rest } = props;
  const [search, setSearch] = useState('');

  function handleChange(ev) {
    setSearch(ev.target.value);
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (search) {
      const url = `https://cse.google.com/cse?cx=010048318871278318416%3Aj-zo6e8pcmw#gsc.tab=0&gsc.q=${search}&gsc.sort=`;
      window.open(url);
    }
  }

  return (
    <Form onSubmit={handleSubmit} {...rest}>
      <InputGroup className="mt-3">
        <Form.Control
          type="search"
          size="sm"
          name="q"
          id="search-text"
          value={search}
          onChange={handleChange}
          autoFocus={autoFocus}
          required
        />
        <Button variant="outline-secondary" type="submit" size="sm" id="search-submit">
          <i className="bi-search fs-5 lh-1" aria-label="Zoeken" />
        </Button>
      </InputGroup>
    </Form>
  );
}

SearchForm.propTypes = {
  autoFocus: PropTypes.bool,
};

export default SearchForm;
