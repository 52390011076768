import { useNavigate } from 'react-router-dom';

function useHandleClick() {
  const navigate = useNavigate();

  function handleClick(ev) {
    const { target, ctrlKey, shiftKey } = ev;
    if (ctrlKey || shiftKey) {
      return;
    }
    const href = target.closest('a[href]')?.getAttribute('href')?.replace(/^https?:\/\/www.avsparta.nl/, '');
    if (href?.startsWith('/')) {
      ev.preventDefault();
      navigate(href);
    }
  }

  return handleClick;
}

export default useHandleClick;
