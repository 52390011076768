import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import SearchForm from './SearchForm';

function SearchButton(props) {
  const { className } = props;
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const ref = useRef(null);

  const popperConfig = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -30],
        },
      },
    ],
  };
  return (
    <div ref={ref} className={className}>
      <Button
        variant="link"
        ref={target}
        size="sm"
        onClick={() => setShow(!show)}
        aria-label="Zoeken"
      >
        <i className="bi-search fs-5 lh-1" />
      </Button>
      <Overlay
        target={target.current}
        show={show}
        placement="left-end"
        rootClose
        onHide={() => setShow(false)}
        popperConfig={popperConfig}
        container={ref.current}
      >
        {({ placement, arrowProps, show: _show, popper, ...rest }) => (
          <div
            {...rest}
            style={{
              position: 'absolute',
              ...rest.style,
            }}
          >
            <SearchForm className="bg-white" autoFocus />
          </div>
        )}
      </Overlay>
    </div>
  );
}

SearchButton.propTypes = {
  className: PropTypes.string,
};

export default SearchButton;
