function processContent(prev, data) {
  if (!data) {
    return { ...prev, loading: true, error: null };
  }
  if (data instanceof Error) {
    return { ...prev, loading: false, error: data };
  }
  const result = {
    content: data,
    main: data.querySelector('main'),
    topPath: data.querySelector('nav#top-path'),
    menu: data.querySelector('nav#left-menu'),
    title: data.querySelector('title')?.textContent,
    loading: false,
    error: null,
    canonical: data.querySelector('link[rel="canonical"]')?.href,
  };
  return result;
}

export default processContent;
