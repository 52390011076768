import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function ExpandButton(props) {
  const { expanded, onClick } = props;
  const buttonClass = classNames('p-0 ps-3 w-auto expand-button accordion-button', { collapsed: !expanded });
  return (
    <button type="button" className={buttonClass} onClick={() => onClick()} aria-label="Toggle expanded" />
  );
}

ExpandButton.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ExpandButton;
