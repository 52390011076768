import { useState } from 'react';
import { useFetcher, useLocation } from 'react-router-dom';
import { useEvent, useInterval } from 'react-use';

function useReload(delay) {
  const [running, setRunning] = useState(true);
  const fetcher = useFetcher();
  const { pathname } = useLocation();

  function reload() {
    fetcher.load(pathname);
  }

  useEvent('visibilitychange', () => {
    if (!running) {
      reload();
    }
    setRunning(document.visibilityState === 'visible');
  });

  useInterval(
    reload,
    running ? delay : null,
  );
}

export default useReload;
