import { useState } from 'react';

const currentTimestamp = () => Math.floor(Date.now() / 1000);

const initialTimestamp = localStorage.getItem('resultsTimestamp') || (currentTimestamp() - 60 * 60 * 24);

function useResultsTimestamp() {
  const [resultsTimestamp, setResultsTimestamp] = useState(initialTimestamp);

  const [resultOpen, setResultOpen] = useState(false);

  function resetRecentClass() {
    document.querySelectorAll('a.new-badge').forEach((el) => {
      el.classList.remove('new-badge');
    });
    document.querySelector('span.new-badge')?.remove();
  }

  function addRecentClass(doc) {
    if (!doc) {
      return;
    }
    let hasNew = false;
    doc.querySelectorAll('a[data-timestamp]').forEach((el) => {
      if (el.dataset.timestamp > resultsTimestamp) {
        el.classList.add('new-badge');
        hasNew = true;
      }
    });
    const header = doc.firstElementChild;
    const hasNewBadge = header.querySelector('.new-badge');
    if (hasNew && !hasNewBadge) {
      const newBadge = document.createElement('span');
      newBadge.classList.add('new-badge');
      newBadge.classList.add('ms-2');
      newBadge.classList.add('badge');
      newBadge.innerText = 'Nieuw';
      doc.firstElementChild.append(newBadge);
    }
    if (hasNewBadge && !hasNew) {
      hasNewBadge.remove();
    }
  }

  function handleSelectResults(id) {
    const isOpenResults = id === 'frontpage-results';
    if (isOpenResults === resultOpen) {
      return;
    }
    setResultOpen(isOpenResults);
    const timestamp = currentTimestamp();
    localStorage.setItem('resultsTimestamp', timestamp);
    if (!isOpenResults) {
      setResultsTimestamp(timestamp);
      resetRecentClass();
    }
  }

  return { resultsTimestamp, handleSelectResults, addRecentClass };
}

export default useResultsTimestamp;
