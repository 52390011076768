function extractHeader(node) {
  const body = node?.cloneNode(true);
  const { id } = node;
  const header = body?.firstElementChild;
  if (header) {
    header.parentNode.removeChild(header);
  }
  return { id, header, body };
}

export default extractHeader;
