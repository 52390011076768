import React from 'react';
import useReload from '../hooks/useReload';
import useResultsTimestamp from '../hooks/useResultsTimestamp';
import Accordion2 from './Accordion2';
import useContent from './ContentProvider/useContent';
import Html from './Html';

function Home() {
  const { main } = useContent();
  const { addRecentClass } = useResultsTimestamp();
  useReload(10 * 60 * 1000); // 10 minutes
  const intro = main?.querySelector('.class-frontpage .left-column');
  const results = main?.querySelector('#frontpage-results');
  addRecentClass(results);
  const agenda = main?.querySelector('#frontpage-agenda');
  const news = main?.querySelector('#frontpage-news');
  return (
    <>
      <Html html={intro?.innerHTML} className="px-auto" />
      <Accordion2 nodes={[results, agenda]} />
      <Html html={news?.innerHTML} className="border-top border-secondary py-2 px-auto" />
    </>
  );
}

export default Home;
