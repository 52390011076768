import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Html from '../../Html';
import MenuListItem from './MenuListItem';
import useContent from '../../ContentProvider/useContent';

function LeftMenu(props) {
  const { show, onHide, className } = props;
  const { menu } = useContent();
  const [expandedItem, setExpandedItem] = useState(null);
  useEffect(() => {
    const selected = menu?.querySelector('.selected')?.closest('li');
    setExpandedItem(selected?.id);
  }, [menu]);

  return (
    <Offcanvas show={show} onHide={onHide} responsive="lg" className={className}>
      <Offcanvas.Header className="bg-sparta" closeButton>
        <Offcanvas.Title className="flex-grow-1"><NavLink to="/">AV Sparta</NavLink></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ul className="list-group list-group-flush w-menu-list">
          {menu && Array.from(menu.children).map((child) => ((child.tagName === 'UL')
            ? Array.from(child.children).map((item) => (
              <MenuListItem
                key={item.id}
                item={item}
                expanded={expandedItem === item.id}
                toggleExpanded={() => setExpandedItem(expandedItem === item.id ? null : item.id)}

              />
            ))
            : (<Html as="li" key={child.id} html={child.outerHTML} className="list-group-item px-1 py-3 text-center" />)))}
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

LeftMenu.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  className: PropTypes.string,
};

export default LeftMenu;
