import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Html from '../../Html';
import ExpandButton from './ExpandButton';

function getClass(linkElem, className) {
  return classNames('list-group-item px-1', className, { active: linkElem.classList.contains('current') });
}

function MenuListItem(props) {
  const { item, expanded, toggleExpanded } = props;
  const header = item.querySelector('a');
  const list = item.querySelector('ul');
  if (!list) {
    return <Html as="li" id={item.id} className={getClass(header)} html={header.outerHTML} />;
  }
  return (
    <li className="list-group-item p-0">
      <ul className="list-group list-group-flush">
        <li className={getClass(header, 'd-flex accordion')}>
          <Html html={header.outerHTML} className="flex-grow-1" />
          <ExpandButton expanded={expanded} onClick={toggleExpanded} aria-label="Toggle expanded" />
        </li>
        {expanded && Array.from(list.children).map((li) => <Html key={li.id} as="li" className={getClass(li.querySelector('a'), 'ps-3')} html={li.querySelector('a').outerHTML} />)}
      </ul>
    </li>
  );
}

MenuListItem.propTypes = {
  item: PropTypes.object,
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func,
};

export default MenuListItem;
