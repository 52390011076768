import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logoFull from '../../images/sparta_fullheader_150h_c.gif';
import hardloopwinkel from '../../images/hardloopwinkel.jpg';
import sport2000 from '../../images/sport2000.jpg';
import SearchForm from './SearchForm';

function Header(props) {
  const { className } = props;
  return (
    <header className={`${className} d-flex`}>
      <Link to="/" className="flex-grow-1 me-3">
        <img src={logoFull} className="img-fluid" alt="AV Sparta header" />
      </Link>
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
          <a href="http://www.dehardloopwinkel.nl/" className="mb-2 me-0 mb-md-0 me-md-2">
            <img src={hardloopwinkel} className="sponsor-logo img-fluid" alt="De Hardloopwinkel" />
          </a>
          <a href="http://www.sport2000.nl/">
            <img src={sport2000} className="sponsor-logo img-fluid" alt="Sport 2000" />
          </a>
        </div>
        <SearchForm className="d-none d-md-block" />
      </div>
    </header>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
