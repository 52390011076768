import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { tagEvent } from '../../hooks/analytics';

function useShowMenu() {
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  function setShowMenuWithEvent(show) {
    tagEvent({ category: 'engagement', action: 'view_item_list', label: 'Toggle Menu', value: show ? 1 : 0 });
    setShowMenu(show);
  }

  useEffect(() => {
    setShowMenu(false);
    window.scrollTo(0, 0);
  }, [pathname]);

  return [showMenu, setShowMenuWithEvent];
}

export default useShowMenu;
