import React from 'react';
import { useLocation } from 'react-router-dom';
import useContent from './ContentProvider/useContent';
import Html from './Html';
import cx from './SolarPanels/cx';

function Node() {
  const { main } = useContent();
  const { pathname } = useLocation();
  const isNews = pathname.startsWith('/Nieuws');
  return (
    <Html html={main?.innerHTML} className={cx('px-auto', !isNews && 'hide-byline')} />
  );
}

export default Node;
