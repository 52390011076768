import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLoaderData } from 'react-router-dom';
import processContent from './processContent';

const initialContent = {};

export const ContentContext = createContext(initialContent);

function ContentProvider(props) {
  const { children } = props;
  const [value, setValue] = useState({});

  function getData() {
    try {
      return useLoaderData();
    } catch (err) {
      return err;
    }
  }
  const data = getData();

  useEffect(() => {
    setValue((prev) => processContent(prev, data));
  }, [data]);

  return (
    <ContentContext.Provider value={value}>
      {children}
    </ContentContext.Provider>
  );
}

ContentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentProvider;
