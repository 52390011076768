import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useHandleClick from './useHandleClick';

function Html(props) {
  const { as, html, loading, className } = props;
  const contentRef = useRef(null);
  const handleClick = useHandleClick();

  useEffect(() => {
    if (contentRef.current && html) {
      contentRef.current.innerHTML = html;
    }
  }, [contentRef.current, html]);
  const Component = as || 'div';
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <Component ref={contentRef} className={className} onClick={handleClick}>{loading}</Component>
  );
}

Html.propTypes = {
  as: PropTypes.string,
  html: PropTypes.string,
  loading: PropTypes.node,
  className: PropTypes.string,
};

export { default as extractHeader } from './extractHeader';
export default Html;
