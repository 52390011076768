import { useEffect } from 'react';
import { pageview } from '../../hooks/analytics';
import useContent from '../ContentProvider/useContent';

function createLinkCanonical() {
  const canonical = document.createElement('link');
  canonical.rel = 'canonical';
  document.head.appendChild(canonical);
  return canonical;
}

function usePage() {
  const { error, loading, title, topPath, canonical } = useContent();

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    if (title || canonical) {
      pageview(title, canonical);
    }
  }, [title, canonical]);

  useEffect(() => {
    const linkCanonical = document.querySelector('link[rel="canonical"]') || createLinkCanonical();
    linkCanonical.href = canonical;
  }, [canonical]);
  return { error, loading, title, topPath };
}

export default usePage;
